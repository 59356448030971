import { makeDataModel } from '@/store/factory/dataModel';
import { makeEmployeesDataModel } from '@/store/modules/db/employeesDataModel';
import profileApi from '@/api/profileApi';
import eventApi from '@/api/eventApi';
import otrsApi from '@/api/otrsApi';
import coffeeBreakApi from '@/api/coffeeBreakApi';
import nfcApi from '@/api/nfcApi';
import billingApi from '@/api/billingApi';
import authApi from '@/api/authApi';
import notifications from './notifications';
import serviceTickets from '@/store/modules/db/serviceTickets';
import acsApi from '@/api/acsApi';
import rbacApi from '@/api/rbacApi';
import offerApi from '@/api/offerApi';
import locationApi from '@/api/locationApi';
import events from '@/store/modules/db/events';
import adminEvents from '@/store/modules/db/adminEvents';
import adminProfiles from '@/store/modules/db/adminProfiles';
import coworkingApi from '@/api/coworkingApi';
import adminCoworkingTickets from '@/store/modules/db/adminCoworkingTickets';
import adminRequests from '@/store/modules/db/adminRequests';
import coworkingTickets from '@/store/modules/db/coworkingTickets';
import newsApi from '@/api/newsApi';
import news from '@/store/modules/db/news';
import hotelApi from '@/api/hotelApi';
import adminHotelTickets from '@/store/modules/db/adminHotelTickets';
import adminCompanies from '@/store/modules/db/adminCompanies';
import docApi from '@/api/docApi';
import adminEventTickets from '@/store/modules/db/adminEventTickets';
import adminDelivery from '@/store/modules/db/adminDelivery';
import bookingApi from '@/api/bookingApi';

export default {
  namespaced: true,
  modules: {
    calendar: makeDataModel({ service: otrsApi.calendar }),
    adminMemberRequests: makeDataModel({ service: profileApi.admin.memberRequest }),
    adminNotificationJobs: makeDataModel({ service: profileApi.admin.notificationJob }),
    companies: makeDataModel({ service: profileApi.company }),
    news: news,
    newsCategories: makeDataModel({ service: newsApi.newsCategory }),
    events: events,
    eventCategories: makeDataModel({ service: eventApi.eventCategory }),
    // userEvents: makeDataModel({ service: eventApi.userEvent }),
    adminNews: makeDataModel({ service: newsApi.admin.news }),
    adminNewsCategories: makeDataModel({ service: newsApi.admin.newsCategory }),
    adminEvents: adminEvents,
    adminEventCategories: makeDataModel({ service: eventApi.admin.eventCategory }),
    adminEventTickets: adminEventTickets,
    memberRequests: makeDataModel({ service: profileApi.memberRequest }),
    profiles: makeDataModel({ service: profileApi.profile, idProp: 'userId' }),
    adminProfiles: adminProfiles,
    notifications: notifications,
    serviceTickets: serviceTickets,
    ticketArticles: makeDataModel({ service: otrsApi.ticketArticle }),
    cbCategories: makeDataModel({ service: coffeeBreakApi.category }),
    cbProducts: makeDataModel({ service: coffeeBreakApi.product }),
    cbAdminCategories: makeDataModel({ service: coffeeBreakApi.admin.category }),
    cbAdminProducts: makeDataModel({ service: coffeeBreakApi.admin.product }),
    cbStockTransactions: makeDataModel({ service: coffeeBreakApi.admin.transaction }),
    adminReservations: makeDataModel({ service: bookingApi.reservation }),
    adminDelivery: adminDelivery,
    adminRequests: adminRequests,
    adminServiceTickets: makeDataModel({ service: otrsApi.admin.serviceTicket }),
    nfcTokens: makeDataModel({ service: nfcApi.token }),
    accessPoints: makeDataModel({ service: nfcApi.accessPoint }),
    bankCards: makeDataModel({ service: billingApi.bankCard, idProp: 'token' }),
    countries: makeDataModel({ service: authApi.country }),
    cities: makeDataModel({ service: authApi.city }),
    locations: makeDataModel({ service: authApi.location }),
    acsAccessPoints: makeDataModel({ service: acsApi.accessPoint }),
    acsDrivers: makeDataModel({ service: acsApi.driver }),
    acsProviders: makeDataModel({ service: acsApi.provider }),
    offerTemplates: makeDataModel({ service: offerApi.offerTemplate }),
    rbacPermissions: makeDataModel({ service: rbacApi.permission }),
    roles: makeDataModel({ service: rbacApi.role }),
    adminRoles: makeDataModel({ service: rbacApi.admin.role }),
    operatorRoles: makeDataModel({ service: rbacApi.operator.role }),
    offers: makeDataModel({ service: offerApi.offer }),
    offerAcceptances: makeDataModel({ service: offerApi.offerAcceptance }),
    units: makeDataModel({ service: locationApi.unit }),
    floorSchemes: makeDataModel({ service: locationApi.floorScheme }),
    priceCategories: makeDataModel({ service: locationApi.priceCategory }),
    meters: makeDataModel({ service: locationApi.counter }),
    meterTypes: makeDataModel({ service: locationApi.counterType }),
    meterReadings: makeDataModel({ service: locationApi.meterReading }),
    adminEmployees: makeEmployeesDataModel({
      service: profileApi.admin.employee,
      dbCompanies: 'db/adminCompanies',
      dbProfiles: 'db/adminProfiles',
      useLocation: true,
    }),
    adminCompanies: adminCompanies,
    employees: makeEmployeesDataModel({
      service: profileApi.employee,
      dbCompanies: 'db/companies',
      dbProfiles: 'db/profiles',
    }),
    myCompanies: makeDataModel({ service: profileApi.my.company }),
    myEmployees: makeDataModel({ service: profileApi.my.employee }),
    myExternalCompanies: makeDataModel({ service: profileApi.my.externalCompany }),
    myOperationCompanies: makeDataModel({ service: profileApi.my.companyOperation }),
    adminAccessLogs: makeDataModel({ service: nfcApi.accessLog }),
    adminStaticPages: makeDataModel({ service: authApi.admin.staticPage }),
    staticPages: makeDataModel({ service: authApi.staticPage }),
    adminWorkPlaces: makeDataModel({ service: coworkingApi.admin.workPlaces }),
    adminCoworkingTickets: adminCoworkingTickets,
    adminHotelRooms: makeDataModel({ service: hotelApi.admin.rooms }),
    adminHotelTickets: adminHotelTickets,
    commonTickets: makeDataModel({ service: otrsApi.commonTicket }),
    coworkingTickets: coworkingTickets,
    hotelTickets: makeDataModel({ service: hotelApi.tickets }),
    stocks: makeDataModel({ service: otrsApi.stocks }),
    adminMenus: makeDataModel({ service: coffeeBreakApi.admin.menu }),
    docTemplates: makeDataModel({ service: docApi.template }),
    docs: makeDataModel({ service: docApi.document }),
    contracts: makeDataModel({ service: docApi.contract }),
    counters: makeDataModel({ service: locationApi.counter }),
    cameras: makeDataModel({ service: locationApi.camera }),
    adminAccessLevels: makeDataModel({ service: acsApi.admin.accessLevel }),
    accessLevels: makeDataModel({ service: acsApi.accessLevel }),
  },
};
